var urlService = process.env.REACT_APP_URL_API; //"https://dev-tramites-admin.scrd.gov.co/api/v1/";
const loader = document.querySelector("#modalLoading"); 

// hiding loading 
const hideLoading = () => {
  if(loader){
    loader.style.display = "none";
  }
}

const get = (nameService) =>
  fetch(`${urlService}${nameService}`)
    .then((r) => {
      //hideLoading(); 
      return r.json();
    });

const post = (nameService, data) =>
  fetch(`${urlService}${nameService}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => {
    //hideLoading(); 
    return r.json();
  });;

const put = (nameService, data) =>
  fetch(`${urlService}${nameService}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

const remove = (nameService, data) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (data) {
    options["body"] = JSON.stringify(data);
  }
  return fetch(`${urlService}${nameService}`, options);
};

const SharedService = {
  get,
  post,
  put,
  remove
};

export default SharedService;
