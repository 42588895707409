/* eslint no-eval: 0 */
import React, { useContext } from 'react'
import { useEffect } from 'react';
import { FormContext } from '../../components/FormContext';

const Select = ({ 
        key, 
        field_id, field_label, field_required, field_class_wrapper, field_placeholder, 
        field_options, field_attributes, field_type, aria_describedby, tooltip_message, field_value, validateError,
        multiple, conditional
    }) => {
    const { handleChange } = useContext(FormContext)

    useEffect(() => {
        if(conditional !== undefined){
            if(conditional !== ''){
                eval(conditional);
            }
        }
        if(document.querySelector(`#${field_id}`).value !== ''){
            document.querySelector(`#${field_id}`).classList.remove('invalid');
        }
        
        if(tooltip_message){ // !== undefined
            //debugger;
            setTimeout(() => {
                var attributes = document.querySelector(`[data-id="${field_id}"]`);
                if(attributes){
                    //let nextSibling = attributes.nextElementSibling;
                    attributes.setAttribute('data-toggle-second', "tooltip");
                    attributes.setAttribute('data-placement', "top");
                    attributes.setAttribute('data-html','true');
                    attributes.setAttribute('title', tooltip_message);
                    eval(`$('#${field_id}').tooltip();`);
                    eval(`$('[data-id=${field_id}]').tooltip();`);
                }
            }, 1000);
        }

        if(validateError){
            if(document.querySelector(`#${field_id}`).classList.contains('invalid')){
                document.querySelector(`[data-id="${field_id}"]`).classList.add('invalid');
                document.querySelector(`[data-id="${field_id}"]`).classList.remove('valid');
            }
        }else{
            if(document.querySelector(`#${field_id}`).classList.contains('valid')){
                document.querySelector(`[data-id="${field_id}"]`).classList.add('valid');
                document.querySelector(`[data-id="${field_id}"]`).classList.remove('invalid');
            }
        }
        if(field_required==='true'){
            document.querySelector(`#${field_id}`).setAttribute('required', 'true');
        }else{
            document.querySelector(`#${field_id}`).removeAttribute('required');
        }
    });

    return (
        <>
            <div className={field_class_wrapper} key={key} id={'group-field-'+field_id}>
                <div className="form-group gov-co-form-group">
                    <div className="gov-co-dropdown">
                        <label className="form-label">{field_label} <span className="asterik-required">{field_required==='true' ? '*':null}</span></label>
                        <select data-placeholder="Selecciona" id={ field_id } data-live-search="true"
                            multiple={multiple} className="selectpicker form-select form-control gov-co-form-control chosen-select" aria-label={aria_describedby}
                            onChange={event => handleChange(field_id, event)} name={field_id}
                            // required={field_required==='true'?true:false}
                        >
                            {!multiple ?<option value=''>Selecciona</option>:''}
                            {field_options.length > 0 && field_options.map((option, i) =>
                                <option value={option.option_label} key={i.toString()}>{option.option_label}</option>

                            )}
                        </select>
                        {validateError && field_required==='true' || (document.querySelector(`#${field_id}`)?.value==='' && document.querySelector(`#${field_id}`)?.required) ? <p><small className="form-text invalid">{validateError}</small></p>: ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Select
