import SharedService from "./shared/sharedService";

const TramitesService = {
    getTramite: (id) => SharedService.get(`tramites/${id}`),
    getTramites: SharedService.get("tramites"),
    postTramites: (data) => SharedService.post("tramites", data),
    putTramites: (data) => SharedService.put("tramites", data),
    deleteTramites: (data) => SharedService.remove("tramites", data),
    displayLoading: () =>{
        const loader = document.querySelector("#modalLoading");
        loader.style.display = "flex";
        // to stop loading after some time
        // setTimeout(() => {
        //     loader.classList.remove("display");
        // }, 5000);
    },
    replaceProp: (obj) => {
        const objNew = {};
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === "object") {
                if (Array.isArray(obj[key])) {
                    const newArray = [];
                    let item = {};
                    obj[key].forEach((x) => {
                        if (key === "field_attributes" || key === "field_error_validaciones") {
                            item[x.key] = x.value;
                        } else {
                            item = TramitesService.replaceProp(x);
                            newArray.push(item);
                        }
                    });
                    if (key !== "field_attributes" && key !== "field_error_validaciones") {
                        objNew[key] = newArray;
                    } else {
                        objNew[key] = item;
                    }
                } else {
                    objNew[key] = TramitesService.replaceProp(obj[key]);
                }
            } else {
                const keyR = key === "_class" ? "class" : key === "_id" ? "id" : key;
                objNew[keyR] = obj[key] ? obj[key] : "";
            }
        });
        return objNew;
    }
};

export default TramitesService;
