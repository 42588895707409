import React from 'react'
import Checkbox from './elements/Checkbox';
import Input from './elements/Input';
import Textarea from './elements/Textarea';
import File from './elements/File';
import Address from './elements/Address';
import Calendar from './elements/Calendar';
import Time from './elements/Time';
import Select from './elements/Select';
import DynamicTable from './elements/DynamicTable';
// Form 
//import { Field } from 'react-final-form'

// const Error = ({ name }) => (
//     <Field name={name} subscription={{ error: true, touched: true }}>
//       {({ meta: { error, touched } }) =>
//         error && touched ? <span>{error}</span> : null
//       }
//     </Field>
// )
  

const Element = ({ field: { 

    key,
    // general properties
    field_id, field_name, field_label, field_required, field_class_wrapper, 
    field_placeholder, field_attributes, field_type, field_value, field_options, aria_describedby,

    //dynamic
    field_structure,

    //tooltip
    tooltip_message,
    
    //files
    field_file,
    multiple, mime_types, max_size_mb, text_tipo_documental, info_adiccional, activar_observaciones, maxlength_comentario,

    //conditional
    conditional
}, validateError }) => {
    // console.log(key);
    switch (field_type) {
        case 'address':
            return (
                <Address
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                field_value={field_value}
                validateError={validateError}

                multiple = {multiple}
                mime_types = {mime_types}
                max_size_mb = {max_size_mb}
                text_tipo_documental = {text_tipo_documental}
                info_adiccional = {info_adiccional}
                activar_observaciones = {activar_observaciones}
                maxlength_comentario = {maxlength_comentario}

                conditional={conditional}
                 />
            )
        case 'calendar':
        case 'date':
            return (
                <Calendar
                    field_id={field_id}
                    field_name={field_name}
                    field_label={field_label}
                    field_required={field_required}
                    field_class_wrapper={field_class_wrapper}
                    field_placeholder={field_placeholder}
                    field_attributes={field_attributes}
                    field_type={field_type}
                    field_structure={field_structure}
                    aria_describedby={aria_describedby}
                    tooltip_message={tooltip_message}
                    field_value={field_value}
                    validateError={validateError}
                    conditional={conditional}
                />
                )
        case 'time':
            return (
                <Time
                    field_id={field_id}
                    field_name={field_name}
                    field_label={field_label}
                    field_required={field_required}
                    field_class_wrapper={field_class_wrapper}
                    field_placeholder={field_placeholder}
                    field_attributes={field_attributes}
                    field_type={field_type}
                    field_structure={field_structure}
                    aria_describedby={aria_describedby}
                    tooltip_message={tooltip_message}
                    field_value={field_value}
                    validateError={validateError}
                    conditional={conditional}
                />
                )
        case 'textarea':            
            return (
            <Textarea
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                tooltip_message={tooltip_message}
                field_value={field_value}
                validateError={validateError}
                conditional={conditional}
            />
            )            
        case 'text':
        case 'number':
        case 'email':
            case 'search':            
            return (
            <Input
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                tooltip_message={tooltip_message}
                field_value={field_value}
                validateError={validateError}
                conditional={conditional}
            />
            )
        case 'select':
            return (<Select
                key={key}
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_options={field_options}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                tooltip_message={tooltip_message}
                field_value={field_value}
                validateError={validateError}  
                multiple = {false}   
                conditional={conditional}           
            />)
        case 'select-multiple': 
            return (<Select
                key={key}
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_options={field_options}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                tooltip_message={tooltip_message}
                field_value={field_value}
                validateError={validateError}  s
                multiple = {true}   
                conditional={conditional}           
            />)

        case 'dynamicTable':
            return (<DynamicTable
                key={key}
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_options={field_options}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                field_value={field_value}
                field_structure={field_structure}
                validateError={validateError}     
                conditional={conditional}           
            />)
        case 'file':           
            return (<File
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_required={field_required}
                field_class_wrapper={field_class_wrapper}
                field_placeholder={field_placeholder}
                field_attributes={field_attributes}
                field_type={field_type}
                aria_describedby={aria_describedby}
                field_value={field_value}
                validateError={validateError}

                multiple = {field_file?.multiple}
                mime_types = {field_file?.mime_types}
                max_size_mb = {field_file?.max_size_mb}
                text_tipo_documental = {field_file?.text_tipo_documental}
                info_adiccional = {field_file?.info_adiccional}
                activar_observaciones = {field_file?.activar_observaciones}
                maxlength_comentario = {field_file?.maxlength_comentario}

                conditional={conditional}
            />)  
        case 'checkbox':
            return (<Checkbox
                field_id={field_id}
                field_label={field_label}
                field_value={field_value}

                conditional={conditional}
            />)

        default:
            return null;
    }


}

export default Element
