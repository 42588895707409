import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import { withRouter } from 'react-router-dom'

class Logout extends Component {

  constructor(props) {
    super(props);
    this.state = { keycloak: null, authenticated: false };
  }

  componentWillMount() {
    const keycloak = Keycloak('/keycloak.json');
    keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).then(authenticated => {
      this.setState({ keycloak: keycloak, authenticated: authenticated })
    });

    //this.logout();
  }

  logout() {
    //this.props.history.push('/')
    this.state.keycloak.logout();
    localStorage.removeItem('userName');
  }

  render() {
    //this.logout();   
    //debugger;
    this.props.history.push('/')
    this.state.keycloak.logout();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('userName');
      // if(this.state.keycloak) {
      //   if(this.state.authenticated) {
      //     this.logout(); 
          
      //     localStorage.removeItem('userName');  
      //     this.props.history.push(`/tramites-y-servicios/${localStorage.getItem('id_tramite')}`);
      //   }
      //   else return (<div>Unable to authenticate!</div>)
      // }
      return (<></>);    
  }
}

export default withRouter(Logout);
