import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppRouter from "./AppRouter";
import Footer from "./components/Footer";
import ImageLogo from "./components/ImageLogo";
import Consulta from './shared/Consulta';
import TableResults from "./components/TableResults";
import UserService from "../services/UserService";


class Main extends Component {
    constructor(props) {
        super(props);
        const host = window.location.host;
        this.state = {
            urlLogin: host + "/auth/realms/SCRD/account/",
            urlProfile: host + "/auth/realms/SCRD/account/",
            userName: ''
        };
        this.state = { value: "", store: "" };
    }
    componentDidMount() {
      window.addEventListener("storage", e =>{
        const user = sessionStorage.getItem('userName');
        this.setState({ userName: user})
      });
    }

    logout = () => {
        //debugger;
        this.props.history.push('/')
        this.props.keycloak.logout();
        sessionStorage.removeItem('userName');
    }

    handleClick(){
        alert('Se hizo click');
    }

    goTo = () => console.log('I got called');
    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
      }

    render(){
        const onLoadMethod = "login-required"; //"login-required"  or "check-sso"
        UserService.setOnloadKeycloak(onLoadMethod);
        return (
            <>
                <govco-header
                    ishome="true" isbeta="false" 
                    usuario = {UserService.getUsername()}

                    url_login = {process.env.REACT_APP_URL_AUTH}
                    auth_active="true"
                    search_active="false"

                    client_id="barra-gov-co-wcmp"
                    onload_keycloak= {onLoadMethod} //"login-required"  or "check-sso" 
                >
                </govco-header>                
                <header>
                    <div className="container">
                        <ImageLogo></ImageLogo>
                    </div>
                </header>

                <section className="body">
                    <govco-accesibility-bar></govco-accesibility-bar>

                    <div className="container">
                        <Router>
                            <Switch>
                                <Route path="/tramites-y-servicios/:id/consultar">
                                    <Consulta></Consulta>
                                </Route>                                      
                                <Route path="/">
                                    <AppRouter></AppRouter>
                                </Route>  
                            </Switch>
                        </Router>                                

                        {
                            localStorage.getItem('resultConsult') ?
                            <Router>
                                <Switch>
                                    <Route exact path="/tramites-y-servicios/:id/consultar">
                                        <TableResults data={localStorage.getItem('resultConsult')}></TableResults>
                                    </Route>                                      
                                </Switch>
                            </Router>:<></>
                        }                      
                    </div>
                </section>
                <component-qualify-procedure 
                    endpoint={this.state.urlEndpoint}
                    id_tramite={this.state.id_tramite}
                    usuario={UserService.getUsername()}
                    fullscreen='true'
                    className="d-flex justify-content-end">
                </component-qualify-procedure>
                <govco-back-to-top></govco-back-to-top>
                <Footer></Footer>

            </>
        );
    }
}

export default Main;