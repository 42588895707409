import React, { Component } from 'react';
import TramitesService from "../../services/TramitesService";

class DudasContacto extends Component {
    constructor(props) {
        super(props);
        //let id = ;
        this.state = {};
        //this.dudas_contacto();
    }

    async componentDidMount() {
        // POST request using fetch with async/await
        //let token = localStorage.getItem('profileToken');
        await this.dudas_contacto();
    }

    async dudas_contacto() {
        this.setState({ 
            id_tramite: `${(this.props.match.params.id).toString().replace('t', '')}` ?? '0',
            area_servicio: await this.getTramite((this.props.match.params.id).toString().replace('t', ''))}
        );
    }

    async getTramite(id) {
        const tramiteResp = await TramitesService.getTramite(id);
        const tramite = TramitesService.replaceProp(tramiteResp);
        return tramite?.area_servicio;
    }

    render() {
        return (
            (this.state.area_servicio ?
            <div>
                <component-doubts-contact 
                    email={this.state.area_servicio?.email}
                    phone={this.state.area_servicio?.telefono}
                    faq={this.state.area_servicio?.preguntas_frecuentes}
                    adicionales={(JSON.stringify(this.state.area_servicio?.adicionales))??''}
                    className="d-flex justify-content-end">
                </component-doubts-contact>
            </div>:<>{this.state.area_servicio?.email}</>
            )
        );
    }
}

export default DudasContacto;