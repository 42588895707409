/* eslint no-eval: 0 */
import React from 'react'
import { useEffect } from 'react';
// import FileBase64 from 'react-file-base64';
// import { useState } from 'react';
// import { FormContext } from '../../components/FormContext';

const File = ({ 
    field_id, field_name, field_label, field_required, field_class_wrapper, 
    field_placeholder, field_attributes, field_type, field_value, aria_describedby, validateError,

    //files
    multiple, mime_types, max_size_mb, text_tipo_documental, info_adiccional, disabled, descripcion, activar_observaciones, maxlength_comentario,

    //conditional
    conditional
 }) => {

    useEffect(() => {
        var attributes = document.querySelector(`#${field_id}`);
            
        if(field_attributes){ // Incluir attributos dinamicos listados en la JSON propiedad 'field_attributes'
            if(attributes){
                var keys = Object.keys(field_attributes);
                keys.map(
                    key =>{
                        attributes.setAttribute(key, field_attributes[key]);
                        return true;
                    }
                )
            }
        }

        if(conditional !== undefined){
            if(conditional !== ''){
                eval(conditional);
            }
        }
        if(document.querySelector(`#${field_id}`)){
            if(document.querySelector(`#${field_id}`).classList.contains('invalid')){
                if(document.querySelector(`#${field_id}`).hasAttribute('required')){
                    document.querySelector(`#${field_id} + label.custom-file-label.label-file-govco`).classList.add('invalid');
                    document.querySelector(`#${field_id} + label.custom-file-label.label-file-govco`).classList.remove('valid');
                }
            }
            if(document.querySelector(`#${field_id}`).classList.contains('valid')){
                document.querySelector(`#${field_id} + label.custom-file-label.label-file-govco`).classList.add('valid');
                document.querySelector(`#${field_id} + label.custom-file-label.label-file-govco`).classList.remove('invalid');
            }
        }
    });

    return (
        <>
            <div className={field_class_wrapper+ ' pt-4'} id={'group-field-'+field_id}>
                <component-upload-files
                    titulo = {field_label}
                    id_input = {field_id}
                    name_input = {field_name}
                    multiple = {multiple}
                    mime_types = {mime_types}
                    max_size_mb = {max_size_mb}
                    text_tipo_documental = {text_tipo_documental}
                    requerido = {field_required}
                    disabled = {disabled}
                    descripción = {descripcion}
                    info_adiccional = {info_adiccional}
                    activar_observaciones = {activar_observaciones}
                    maxlength_comentario = {maxlength_comentario}
                    validate_error={validateError}
                    aria_describedby={aria_describedby}
                    env={process.env.REACT_APP_ENVIRONMENT}
                />
                {/* {validateError? <p><small className="form-text invalid">{validateError}</small></p>: ''} */}
            </div>            
        </>
    )
}

export default File
