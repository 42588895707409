/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, {Component} from 'react';
import TramitesService from "../../services/TramitesService";

class Tutoriales extends Component {

    constructor(props) {
        super(props);
        let id = (props.match.params.id).toString().replace('t', '');


        this.state = {
            id_tramite: `${
                id.toString().replace('t', '')
            }` ?? '0',
            te_explicamos: '#'
        }
    }

    componentDidMount() { 
        this.te_explicamos();
    }

    async te_explicamos() {
        this.setState({
            te_explicamos: await this.getTramite(
                (this.props.match.params.id).toString().replace('t', '')
            )
        });
    }

    async getTramite(id) {
        const tramiteResp = await TramitesService.getTramite(id);
        const tramite = TramitesService.replaceProp(tramiteResp);
        return tramite?.te_explicamos ?? '#';
    }

    render() {
        return (
            <div className="tarjeta-ayuda bg-color-concrete">
                <a target="_blank" rel="noopener noreferrer"
                    href={this.state?.te_explicamos ?? '!#'}
                    title="Te explicamos con tutoriales, te indica como debes realizar tu trámite">
                    <span className="">Te explicamos con Tutoriales</span>
                </a>
            </div>
        );
    }
}

export default Tutoriales;
