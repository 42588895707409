/* eslint no-eval: 0 */
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
// import { Form } from 'react-bootstrap';
import { FormContext } from '../../components/FormContext';

const Time = ({ 
    field_id, field_name, field_label, field_required, field_class_wrapper, field_structure,
    field_placeholder, field_attributes, field_type, field_value, aria_describedby, tooltip_message, validateError, 

    conditional
 }) => {

    const { handleChange } = useContext(FormContext)
    const [time, setTimeState] = useState(0);
    const [valueTime, setValueTime] = useState(field_value);

    useEffect(() => {
        var attributes = document.querySelector(`#${field_id}`);
            
        if(field_attributes){ // Incluir attributos dinamicos listados en la JSON propiedad 'field_attributes'
            if(attributes){
                var keys = Object.keys(field_attributes);
                keys.map(
                    key =>{
                        attributes.setAttribute(key, field_attributes[key]);
                        return true;
                    }
                )
            }
        }
        if(conditional !== undefined){
            if(conditional !== ''){
                eval(conditional);
            }
        }

        if(tooltip_message!== undefined){
            attributes.setAttribute('data-toggle', "tooltip");
            attributes.setAttribute('data-placement', "top");
            attributes.setAttribute('title', tooltip_message);
            if(tooltip_message){
                attributes.setAttribute('data-toggle', "tooltip");
                //attributes.setAttribute('data-placement', "top");
                attributes.setAttribute('title', tooltip_message);
                eval(`$('#${field_id}').tooltip();`);
            }
        }

        if(validateError){
            if(document.querySelector(`#${field_id}`).classList.contains('invalid')){
                document.querySelector(`#${field_id}`).classList.add('invalid');
                document.querySelector(`#${field_id}`).classList.remove('valid');
            }
        }else{
            if(document.querySelector(`#${field_id}`).classList.contains('valid')){
                document.querySelector(`#${field_id}`).classList.add('valid');
                document.querySelector(`#${field_id}`).classList.remove('invalid');
            }
        }
        
        if(field_required==='true'){
            document.querySelector(`#${field_id}`).setAttribute('required', 'true');
        }else{
            document.querySelector(`#${field_id}`).removeAttribute('required');
        }

        if(validateLoadStyle() && validateLoadScript()){
            if(time===0){
                setTimeout(() => {
                    eval(`$('#${field_id}').timepicker({
                        modal: false,
                        locale: 'es-es',
                        uiLibrary: 'bootstrap4',
                        useCurrent: false,
                        format: 'hh:MM TT',
                        mode: 'ampm'
                    });
                    
                    //$('#${field_id}').focus(function(){$('#${field_id} + span button').click()})`);
                    setTimeState(1);           
                }, 1000);
            }
        }

    }, [valueTime]);
    
    const validateLoadStyle = async () =>{
        let stylesArray = await Array.from(document.styleSheets).map(item => {return item.href});   

        if(await !stylesArray.includes("https://unpkg.com/gijgo@1.9.13/css/gijgo.min.css")){
            const commentCSS = document.createComment("<!-- CSS Timeio  -->");
            const timeCSS = document.createElement("link");
        
            timeCSS.href="https://unpkg.com/gijgo@1.9.13/css/gijgo.min.css";
            timeCSS.rel="stylesheet";
            timeCSS.type="text/css";
            document.head.appendChild(commentCSS);
            document.head.appendChild(timeCSS);
            return true;
        }
        return false;
    }

    const validateLoadScript = async () =>{
        let scriptsArray = await Array.from(document.scripts).map(item => {return item.src});
    
        if(await !scriptsArray.includes("https://unpkg.com/gijgo@1.9.13/js/gijgo.min.js") || 
            !scriptsArray.includes("https://unpkg.com/gijgo@1.9.13/js/messages/messages.es-es.js")){
            const commentJS = document.createComment("<!-- JS Timeio  -->");
            const timeJS = document.createElement("script");
            const timeLangJS = document.createElement("script");
        
            timeJS.src="https://unpkg.com/gijgo@1.9.13/js/gijgo.min.js";
            timeJS.type="text/javascript";

            timeLangJS.src="https://unpkg.com/gijgo@1.9.13/js/messages/messages.es-es.js";
            timeLangJS.type="text/javascript";
        
            document.body.appendChild(commentJS);
            document.body.appendChild(timeJS);
            document.body.appendChild(timeLangJS);
            
            return true;
        }
        return false;
    }


    const handleBlur=()=>{
        setTimeout(() => {
            field_value = document.querySelector(`#${field_id}`).value;
            if(field_value){
                document.querySelector(`#${field_id}`).classList.add('valid');
                document.querySelector(`#${field_id}`).classList.remove('invalid');
            }
            setValueTime(field_value);
        }, 500)
    }


    return (
        <div className={field_class_wrapper} id={'group-field-'+field_id}>

             <div className="form-group gov-co-form-group">
                <label htmlFor={field_id} className="form-label">{field_label} <span className="asterik-required">{field_required==='true' ? '*':null}</span></label>
                <input className="timepicker form-control gov-co-form-control" 
                    id={field_id} name={field_name} aria-describedby={aria_describedby}
                    placeholder={field_placeholder ? field_placeholder : ''}
                    onChange={event => {handleChange(field_id, event);}}
                    onBlur={event => {handleBlur(); handleChange(field_id, event);}}
                    value={valueTime}
                />
                {validateError && field_required==='true' && document.querySelector(`#${field_id}`).value==='' ? <p><small className="form-text invalid">{validateError}</small></p>: ''}
                
            </div>
        </div>
    )
}

export default Time
