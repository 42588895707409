import React, { Component } from 'react';
class Breadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
    render() {
        return (
            <>
                <div className="row">
                    <nav className="mt-4" aria-label="Miga de pan, usted esta en:">
                        <ol className="breadcrumb gov-co-breadcrumb">
                            <li className="breadcrumb-item">
                                {/* <a href={`https://www.gov.co/ficha-tramites-y-servicios/${(this.props.id_tramite).toUpperCase()}`}>Inicio</a> */}
                                {/* <a href={`https://www.culturarecreacionydeporte.gov.co/ `}>Inicio</a> */}
                                <a href={`/tramites-y-servicios/${this.props.id_tramite}`}>Inicio</a>
                            </li>
                            {/* { this.props.nombre_tramite === '' ?
                                <li className='breadcrumb-item'>
                                    <a href={`https://d92021.culturarecreacionydeporte.gov.co/tramites-y-servicios`}>
                                        Trámites y Servicios
                                    </a>
                                </li>: <></>
                            } */}
                            {this.props.nombre_tramite ?
                                <li className={'breadcrumb-item ' + (this.props.nombre_action ? 'active':'')}>
                                    <a href={`/tramites-y-servicios/${this.props.id_tramite}`}>
                                        {this.props.nombre_tramite}
                                    </a>
                                </li>: <></>
                            }
                            { 
                                this.props.nombre_action ? 
                                <li className="breadcrumb-item active" aria-current="page">
                                    {/* Crear solicitud o Consultar trámite*/}
                                    {this.props.nombre_action}
                                </li>: <></>
                            }
                        </ol>
                    </nav>
                </div>              
            </>
        );
    }
}

export default Breadcrumbs;
