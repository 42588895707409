import React, {Component} from 'react';
//import { ReCAPTCHA } from "react-google-recaptcha";

class PopUpLOA extends Component {

    closeModal =() =>{
        const mLOA = document.querySelector(`.bd-example-modal-lg-${this.props.index}`);
        mLOA.style.display = "none";
        document.querySelector(`.btn-cerrar-sesion`).click();
    }
    render() {
        // const SITE_KEY = "6LcS6zocAAAAAKkapsCxZ2IoQYm8GCnKfN8c-1i-";
        // const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.

        return (
            <>
                <div className={`modal bd-example-modal-lg-${this.props.index}`} tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
					{/* <div className="modal-dialog modal-lg"> */}
                    <div className="modal-dialog gov-co-modal gov-co-modal-error" role="document">
						{/* <div className="modal-content ">
							<div className="modal-header">
								<button type="button" className="close color-marine bg-color-marine" data-dismiss="modal" aria-hidden="true" aria-label="Cerrar ventana" title="Cerrar ventana">x</button>
							</div>

							<div className="texto">
                                <h4>
                                    TERMINOS Y CONDICIONES DE USO DEL SISTEMA.
                                </h4>

								<p>La información y documentación adjunta se presenta bajo la gravedad de juramento, razón por la cual la administración, amparada en el principio de buena fe, le dará los efectos jurídicos requeridos para aplicar las disposiciones contenidas en la Ley 1581 de 2012. Lo anterior no es óbice para que la administración se reserve la facultad de realizar las indagaciones necesarias para verificar la información suministrada. En caso de encontrar información presuntamente falsa o inexacta, instaurará las acciones legales aplicables según la legislación vigente<br/><br/>
                                </p>
                                
                                <h4>POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES</h4>

                                <p>
                                    La información y los datos suministrados son confidenciales, serán usados con fines estadísticos y para envío de información institucional e interinstitucional relacionada con lo indicado en la Ley 1581 de 2012, de Protección de datos personales
								</p>
                                <form action="" method="post">
                                    <div className="row col-12">
                                        <h2 className="h3-tramite mb-4">
                                            Aviso de privacidad y autorización para el tratamiento de datos personales
                                        </h2>
                                        <label className="checkbox-govco">
                                            <input type="checkbox" id="datos-personales" />
                                            <label htmlFor="datos-personales">Autorizo el tratamiento de mis datos
                                                personales</label>
                                        </label>
                                        <label className="checkbox-govco">
                                            <input type="checkbox" id="terminos-condiciones" />
                                            <label htmlFor="terminos-condiciones">Acepto términos y condiciones</label>
                                        </label>
                                    </div>
                                    <div className="pb-4">
                                    <ReCAPTCHA
                                        ref={(r) => this.recaptcha = r}
                                        sitekey={SITE_KEY}
                                        grecaptcha={grecaptchaObject}
                                    />
                                    </div>
                                    <div className="text-center">      
                                        <button data-dismiss="modal" className="btn btn-round btn-middle m-2">Rechazar</button>
                                        <a href={this.props.url} className="btn btn-round btn-high m-2">Aceptar</a>
                                    </div>
                                </form>
							</div>

						</div> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="gov-co-modal-type">
                                <span className="govco-icon govco-icon-tramite-error"></span>
                                <h2>Lo sentimos</h2>
                                </div>
                                <div className="gov-co-modal-message">
                                    <p>Usted se ha autenticado con nivel de confianza bajo, para acceder al trámite se requiere autenticación con nivel de confianza medio.</p>
                                    <p>Por favor autentiquese nuevamente con un nivel de confianza medio.</p>
                                </div>
                                <div className="text-center">      
                                    <button data-dismiss="modal" className="btn btn-round btn-high m-2" onClick={this.closeModal}>Salir del trámite</button>
                                    {/* <a href={this.props.url} className="btn btn-round btn-high m-2">Salir del trámite</a> */}
                                </div>
                            </div>
                            {/* <div class="modal-footer center">
                                <button type="button" class="btn btn-round btn-high" data-dismiss="modal">boton</button>
                                <button type="button" class="btn btn-round btn-middle" data-dismiss="modal">boton</button>
                            </div> */}
                        </div>
					</div>
				</div>
            </>
        );
    }
}
export default PopUpLOA;
