/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React from "react";
import OneStepContainer from "../steps/oneStepContainer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TramitesService from "../../services/TramitesService";

const Tramite = () => {
  const { id } = useParams();
  const [tramite, setTramite] = useState({})

  const getTramite = async () => {
    //TramitesService.displayLoading();
    let isSubscribed = true;
    const idTramite = id.replace("t", "");
    const tramiteResp = await TramitesService.getTramite(idTramite);
    const tramiteLoad = TramitesService.replaceProp(tramiteResp);

    if (isSubscribed) {
      setTramite(tramiteLoad);
      if (tramiteLoad.tramite !== null || undefined) {
        document.title = `SCRD | ${tramiteLoad?.titulo ?? ""}`;
      }
    }
    return () => (isSubscribed = false)
  };

  const state = {
    tramite: tramite,
  };

  localStorage.setItem("id_tramite", id);

  useEffect(() => {
    getTramite();
  }, []);

  const stepEmsablador = state.tramite ? (
    <OneStepContainer
      id_tramite={id}
      title={state.tramite.titulo}
      description={state.tramite.descripcion}
      tipo_solicitud={state.tramite.tipo_solicitud}
      action_links={state.tramite.action_links}
      formulario={state.tramite.form_tramite}
      loa={state?.tramite?.loa_permitido}
    />
  ) : (
    <>
      <h1>Página no encontrada 404</h1>
      <h2 className="h1-tramite mt-5">
        El trámite {id} no ha sido registrado en el sistema
      </h2>
    </>
  );

  return <>{stepEmsablador}</>;
};

export default Tramite;
