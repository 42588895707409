import React, { Component } from 'react';
//import UserInfo from './UserInfo';
// import Logout from './Logout';
// import QueryAPI from './QueryAPI';
import Keycloak from 'keycloak-js';
class PersonlizarRedirect extends Component {

  constructor(props) {
    super(props);
    this.state = { keycloak: null, authenticated: false };
  }

  componentWillMount() {
    const keycloak = Keycloak('/keycloak.json');
    keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).then(authenticated => {
      this.setState({ keycloak: keycloak, authenticated: authenticated })
    });
  }

  logout() {
    this.state.keycloak.logout();
    localStorage.removeItem('userName');
  }

  render() {
    console.log(this.state.keycloak);
    if(this.state.keycloak) {
      if(this.state.authenticated) {
        this.state.keycloak.loadUserInfo().then((userInfo) => {
          localStorage.setItem('userName', userInfo.preferred_username);
          window.location.href = `${process.env.REACT_APP_URL_AUTH}/auth/realms/SCRD/account/`;
        }); 
        return (
        <div></div>
        );
      } else return (<div>Unable to authenticate!</div>)
    }
    return (
      <div>
        <p>Initializing Keycloak...</p>
      </div>
    );
  }
}

export default PersonlizarRedirect;
