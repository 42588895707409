/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React from 'react';
import TramitesService from "../../services/TramitesService";
import FourStepContainer from '../steps/fourStepContainer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Consulta = () => {
    const {id} = useParams();
    const [tramite, setTramite] = useState({})

    const getTramite = async () => {
        let isSubscribed = true;
        const idTramite = id.replace("t", "");
        const tramiteResp = await TramitesService.getTramite(idTramite);
        const tramiteLoad = TramitesService.replaceProp(tramiteResp);
    
        if (isSubscribed) {
          setTramite(tramiteLoad);
          if (tramiteLoad.tramite !== null || undefined) {
            document.title = `SCRD | ${tramiteLoad?.titulo ?? ""}`;
          }
        }
        return () => (isSubscribed = false)
    };

    const state = {
        tramite: tramite
    };

    useEffect(() => {
        if (state.tramite !== null || undefined) {
            document.title = `SCRD | ${state.tramite?.titulo ?? ""}`;
        }
        getTramite();
    }, []);

    const stepEmsablador = state.tramite ? (
        <>
            {JSON.stringify(state?.tramite) !== JSON.stringify({})?
                <FourStepContainer 
                id_tramite = {id}
                title = {state?.tramite?.titulo} 
                description = {state?.tramite?.descripcion_corta}  
                formulario = {state?.tramite?.form_consulta}
            />
            :<></>
            }
        </>) : (
        <>
            <h1>Página no encontrada 404</h1>
            <h2 className="h1-tramite mt-5">
                El trámite {id} no ha sido registrado en el sistema
            </h2>
        </>
    );

    return (<>{stepEmsablador}</>);
}
export default Consulta;