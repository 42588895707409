/* eslint no-eval: 0 */
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { FormContext } from '../../components/FormContext';

const Calendar = ({ 
    field_id, field_name, field_label, field_required, field_class_wrapper, field_structure,
    field_placeholder, field_attributes, field_type, field_value, aria_describedby, tooltip_message, validateError, 

    conditional
 }) => {

    const { handleChange } = useContext(FormContext)
    const [calendar, setCalendarState] = useState(0);
    const [valueCalendar, setValueCalendar] = useState(field_value);

    const formatDate = (inputDate) => {
      let date, month, year;
    
      date = inputDate.getDate();
      month = inputDate.getMonth() + 1;
      year = inputDate.getFullYear();
    
        date = date
            .toString()
            .padStart(2, '0');
    
        month = month
            .toString()
            .padStart(2, '0');
    
      return `${date}/${month}/${year}`;
    }

    useEffect(() => {
        var attributes = document.querySelector(`#${field_id}`);
            
        if(field_attributes){ // Incluir attributos dinamicos listados en la JSON propiedad 'field_attributes'
            if(attributes){
                var keys = Object.keys(field_attributes);
                keys.map(
                    key =>{
                        attributes.setAttribute(key, field_attributes[key]);
                        return true;
                    }
                )
            }
        }
        if(conditional !== undefined){
            if(conditional !== ''){
                eval(conditional);
            }
        }

        if(tooltip_message!== undefined){
            attributes.setAttribute('data-toggle', "tooltip");
            attributes.setAttribute('data-placement', "top");
            attributes.setAttribute('title', tooltip_message);
            if(tooltip_message){
                attributes.setAttribute('data-toggle', "tooltip");
                //attributes.setAttribute('data-placement', "top");
                attributes.setAttribute('title', tooltip_message);
                eval(`$('#${field_id}').tooltip();`);
            }
        }

        if(validateError){
            if(document.querySelector(`#${field_id}`).classList.contains('invalid')){
                document.querySelector(`#${field_id}`).classList.add('invalid');
                document.querySelector(`#${field_id}`).classList.remove('valid');
            }
        }else{
            if(document.querySelector(`#${field_id}`).classList.contains('valid')){
                document.querySelector(`#${field_id}`).classList.add('valid');
                document.querySelector(`#${field_id}`).classList.remove('invalid');
            }
        }
        
        if(field_required==='true'){
            document.querySelector(`#${field_id}`).setAttribute('required', 'true');
        }else{
            document.querySelector(`#${field_id}`).removeAttribute('required');
        }

        if(validateLoadStyle() && validateLoadScript()){
            if(calendar===0){
                setTimeout(() => {
                    eval(`$('#${field_id}').datepicker({
                        locale: 'es-es',
                        format: 'dd/mm/yyyy',
                        uiLibrary: 'bootstrap4',
                        keyboardNavigation: true,
                        icons: {
                            rightIcon: '<span aria-label="Abrir calendario"><i class="material-icons">date_range</i></span>'
                        }
                    });`);
                    setCalendarState(1);
                    if(field_structure){
                        if(JSON.parse(field_structure).today){
                            setValueCalendar(formatDate(new Date()));
                            eval(`$('#${field_id}').datepicker("value", "${valueCalendar}");`);
                        }
                    }             
                }, 1000);
            }
        }

    }, [valueCalendar]);
    
    const validateLoadStyle = async () =>{
        let stylesArray = await Array.from(document.styleSheets).map(item => {return item.href});   

        if(await !stylesArray.includes("https://unpkg.com/gijgo@1.9.13/css/gijgo.min.css")){
            const commentCSS = document.createComment("<!-- CSS Calendario  -->");
            const calendarCSS = document.createElement("link");
        
            calendarCSS.href="https://unpkg.com/gijgo@1.9.13/css/gijgo.min.css";
            calendarCSS.rel="stylesheet";
            calendarCSS.type="text/css";
            document.head.appendChild(commentCSS);
            document.head.appendChild(calendarCSS);
            return true;
        }
        return false;
    }

    const validateLoadScript = async () =>{
        let scriptsArray = await Array.from(document.scripts).map(item => {return item.src});
    
        if(await !scriptsArray.includes("https://unpkg.com/gijgo@1.9.13/js/gijgo.min.js") || 
            !scriptsArray.includes("https://unpkg.com/gijgo@1.9.13/js/messages/messages.es-es.js")){
            const commentJS = document.createComment("<!-- JS Calendario  -->");
            const calendarJS = document.createElement("script");
            const calendarLangJS = document.createElement("script");
        
            calendarJS.src="https://unpkg.com/gijgo@1.9.13/js/gijgo.min.js";
            calendarJS.type="text/javascript";

            calendarLangJS.src="https://unpkg.com/gijgo@1.9.13/js/messages/messages.es-es.js";
            calendarLangJS.type="text/javascript";
        
            document.body.appendChild(commentJS);
            document.body.appendChild(calendarJS);
            document.body.appendChild(calendarLangJS);
            
            return true;
        }
        return false;
    }


    const handleBlur=()=>{
        setTimeout(() => {
            field_value = document.querySelector(`#${field_id}`).value;
            if(field_value){
                document.querySelector(`#${field_id}`).classList.add('valid');
                document.querySelector(`#${field_id}`).classList.remove('invalid');
            }
            setValueCalendar(field_value);
        }, 500)
    }


    return (
        <div className={field_class_wrapper} id={'group-field-'+field_id}>

             <div className="form-group gov-co-form-group">
                <label htmlFor={field_id} className="form-label">{field_label} <span className="asterik-required">{field_required==='true' ? '*':null}</span></label>
                <input className="calendar form-control gov-co-form-control" 
                    id={field_id} name={field_name} aria-describedby={aria_describedby}
                    placeholder={field_placeholder ? field_placeholder : ''}
                    onChange={event => {handleChange(field_id, event);}}
                    onBlur={event => {handleBlur(); handleChange(field_id, event);}}
                    value={valueCalendar}
                />
                {validateError && field_required==='true' && document.querySelector(`#${field_id}`).value==='' ? <p><small className="form-text invalid">{validateError}</small></p>: ''}
                
            </div>
        </div>
    )
}

export default Calendar
