import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PopUpLOA from './PopUpLOA';
import Skeleton from 'react-loading-skeleton'
// import PopUpTramite from './PopUpTramite';
class OptionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {loa:"2"};
    }
    componentDidMount(){
        this.setState = {
            loa:  document.getElementById("userKeycloak") ? document.getElementById("userKeycloak").getAttribute('data-loa'): "1"
        }
    }
    render(){
        return (
            <>
                <div className="tarjeta-acciones mb-4">
                    {/* Con popup */}
                    {(!(this.props.loa_permitido).includes(this.state.loa))?
                        <div className="head-acciones">
                            <h4 data-toggle="modal" data-target={`.bd-example-modal-lg-${this.props.index}`}>{this.props.titulo}</h4>
                        </div>:
                        <Link to={`${this.props.url}`} className="head-acciones">
                            <h4>{this.props.titulo}</h4>
                        </Link>
                    }
                    <div className="card-body">
                        <p className="card-text">{this.props.descripcion}</p>
                    </div>
                </div>
                {/* {this.props.url} */}
                {/*<PopUpTramite index={this.props.index} url={this.props.url}></PopUpTramite>*/}
                { (!(this.props.loa_permitido).includes(this.state.loa))? <PopUpLOA index={this.props.index} url={this.props.url}></PopUpLOA>:<></>}
            </>
        );
    }
}

export default OptionCard;