import React, {Component}from 'react';
import { Link } from 'react-router-dom';
//import Select from 'react-select';
import Breadcrumbs from "../components/Breadcrumbs";
import OptionCard from '../components/OptionCard';
import TabsProgresoTramite from "../components/TabsProgresoTramite";
class ThreeStepContainer extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0,0);
    }
    render_action_links(actions, id){
        return actions.map((item) => {
            return (item.state === 'active')?
            (
                <div className="col-lg-4">
                    <OptionCard titulo={item.nombre} 
                        descripcion={item.descripcion} 
                        url={`/tramites-y-servicios/${this.props.id_tramite}/${item.action_url}`}>
                    </OptionCard>
                </div>
            ):<></>;
        });
    }

    async download_comprobante(){
        const objRadicado = JSON.parse(localStorage.getItem('radicado'));
        let token = localStorage.getItem('profileToken');

        fetch(`${process.env.REACT_APP_URL_ADMIN}/api/v1/tramites/datos-basicos/${objRadicado.radicado}/comprobante/`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            //a.target = '_blank';
            a.download = `${objRadicado.radicado}`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });
    }

    render(){
        const prevUrl = `/tramites-y-servicios/${this.props.id_tramite}`;
        const objRadicado = JSON.parse(localStorage.getItem('radicado'));
        console.log(objRadicado);
        //conso
        var ts_hms = objRadicado.fecha_radicacion;//new Date(objRadicado.fecha_radicacion);
        //let time = ts_hms.format("%Y-%m-%d %H:%M:%S");
        return (
            <>
                <Breadcrumbs id_tramite={this.props.id_tramite} nombre_tramite={this.props.title} nombre_action={'Resultado'} />
                {/* <!-- TERCER PASO --> */}
                <main className="pb-5">
                    <TabsProgresoTramite currentStep={3} prevUrl={`${prevUrl}/radicar`}></TabsProgresoTramite>
                    <h1 className="h1-tramite mt-5">
                        {this.props.title}
                    </h1>

                    <div className="alert gov-co-alert-sucess mt-4">
                        <span className="govco-icon govco-icon-tramite-en-linea"></span>
                        La solicitud se ha realizado y enviado correctamente
                    </div>
                    <p>
                        Muchas Gracias por utilizar los servicios en línea de la Secretaría de Cultura, Recreación y
                        Deporte. Para hacerle seguimiento a este trámite, no olvide apuntar el <strong>Número del radicado</strong> presentado a continuación.
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: this.props?.response_radicado?.template_msj}}></p>
                    <h2 className="h3-tramite mt-5">Número de radicado:</h2>
                    <p>{objRadicado?.radicado}</p>
                    
                    <p className="mt-2">
                        <strong>Fue radicado:</strong> {ts_hms}
                    </p>
                    <p className="mt-2"><strong>Tiempo estimado para la respuesta:</strong> {this.props?.response_radicado?.tiempo_estimado}</p>
                    <p className="links-actions">
                        <button className="btn btn-round btn-high boton-inicio px-4" onClick={this.download_comprobante}>Descargar Comprobante</button>
                    </p>
                    <Link to={`${prevUrl}`} className="btn btn-round btn-middle boton-inicio">Salir</Link>
                </main>
            </>
        );
    }
}

export default ThreeStepContainer;