/* eslint no-eval: 0 */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, {useState } from 'react'
import { useEffect } from 'react';

const DynamicTable = ({ 
        key, 
        field_id, field_name, field_label, field_placeholder, field_required, field_class_wrapper, field_value,
        field_structure, field_options
        // field_placeholder, field_attributes, field_type, aria_describedby, field_value, validateError,
        
    }) => {
    const [tableData, setTableData] = useState(JSON.stringify({}));
    const [rowsRender, setRowsRender] = useState(undefined);

    const convertToSlug = (Text) =>{
        return Text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    }

    useEffect(() => {
        const getRowsData = (index) => {
            if(index){
                let row = field_options.length > 0 && field_options.map((option) => {
                    let name_slug = convertToSlug(option.option_label);
                    return <td key={'row-table-'+index}>
                        <input type="text" id={name_slug} name={name_slug} className="input-dynamic-table" onChange={(e=>updateRowData(index, name_slug, e.target.value))} required/>
                    </td>
                })
                
                return <tr>{row}</tr>;
            }
        }

        if(field_options.length > 0){
            if(rowsRender === null){
                setRowsRender(getRowsData(0));
            }   
        }
    }, []);

    const updateRowData =function (index, attrib, value) {
        let jsonRows = JSON.parse(tableData);
        
        if(Array.isArray(jsonRows)){
            jsonRows[index][attrib] = value;
        }else{
            jsonRows[attrib] = value;
        }
        
        setTableData(JSON.stringify(jsonRows));
        console.log(tableData);
    }


    return (
        <>
            <component-json-table-crud 
                titulo={field_label} 
                id_input={field_id} 
                name_input={field_name}
                cols={field_structure}
                description={field_placeholder}
                _class={field_class_wrapper}>
            </component-json-table-crud>
        </> 
    )
}

export default DynamicTable
