import React, { Component } from 'react';
import Breadcrumbs from "../components/Breadcrumbs";
import TabsProgresoTramite from "../components/TabsProgresoTramite";
import { useState, useEffect } from 'react';
import Element from '../components/Element';
import { FormContext } from '../components/FormContext';
import { Link, useHistory } from 'react-router-dom';
import Secured from '../components-keycloak/Secured';
import ReCAPTCHAInput from '../components/ReCAPTCHAInput';
import PopUpLOA from '../components/PopUpLOA';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function LoadForm(props) {
    const [elements] = useState(props.dinamyc_form);
    const { fields, name_group, description_group } = elements ?? {}
    const classFirst = "h2-tramite "; // + (props.index < 1 || !exist_datos_basicos ? 'mt-5 ': '');

    const validatePattern = (field) => {
        if (props.firstState) {
            const $input = document.getElementById(field.field_id);

            console.log($input.id);
            console.log($input.validity);

            if(!$input.validity.valid){
                if($input.validity.valueMissing){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return "Este campo es requerido"; //field.field_error_validaciones.pattern
                }
                if($input.validity.patternMismatch && $input.value !== ''){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return field.field_error_validaciones.pattern
                }
                if($input.validity.badInput){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return "Has ingresado un valor que el navegador no puede convertir, por favor ingreselo nuevamente"
                }

                //typeMismatch
                if($input.validity.typeMismatch){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return "Has ingresado un valor incorrecto";
                }
                
                //tooLong
                if($input.validity.tooLong){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return field.field_error_validaciones.maxlength; // Max Length 
                }

                //tooShort
                if($input.validity.tooShort){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return field.field_error_validaciones.minlength; // Min Length 
                }
                
                //rangeOverflow
                if($input.validity.rangeOverflow){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                    return field.field_error_validaciones.range; // Si se habilita un input de tipo range
                }

            }
            if($input.type === 'textarea'){
                //alert('hhhhhh')
                if(($input.required && $input.value !== '') && ($input.value).length <= $input.minLength){
                    $input.classList.add("invalid")
                    $input.classList.remove("valid");
                }
            }

            $input.classList.add("valid")
            $input.classList.remove("invalid");
            return '';

        }
    }
    const convertToSlug = (Text) => {
        return Text
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-');
    }

    return (
        <>
            <h2 className={classFirst} id={'title-' + convertToSlug(name_group)}>
                {name_group || <Skeleton />}
            </h2>
            {description_group ? <div className="mt-4" id={'description-' + convertToSlug(name_group)} dangerouslySetInnerHTML={{ __html: description_group }}></div> : null}
            <div className={`row mt-4 align-items-end`} id={`body-${convertToSlug(name_group)}`}>
                {fields ? fields.map((field, i) =>
                    <Element key={(i).toString()} field={field} validateError={validatePattern(field)} />
                ) : null}
            </div>
        </>
    );
}


function DynamicForm(props) {
    const [elements, setElements] = useState(props?.dinamyc_form);
    const [submitFirst, setSubmitFirst] = useState(false);

    let history = useHistory();

    const sendRequestForm = async (fieldsJSON, filesJSON) => {
        let token = localStorage.getItem('profileToken');
        console.log(token);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Accept': '*/*'
            },
            body: JSON.stringify({
                "id_tramite": `${(props?.id).replace('t','')}`,
                "tipo_tramite": `${props?.tipo_solicitud}`, // esal // servicio //bic // OPA
                "usuario": `${props?.usuario}`, //${document.getElementById('userId') ? document.getElementById('userId').value : props?.usuario}
                "backend": "esal", // FORMULARIO
                "estado": "activo",
                "objeto_negocio": JSON.stringify(fieldsJSON),
                "anexos": `[${JSON.stringify(filesJSON)}]`
            })
        };

        // console.log(requestOptions);
        // console.log(process.env.REACT_APP_URL_ADMIN);
        const response = await fetch(`${process.env.REACT_APP_URL_ADMIN}/api/v1/tramites/datos-basicos/`, requestOptions);

        if (response.ok) {
            const data = await response.json();
            console.log(data.radicado);

            localStorage.setItem('radicado', JSON.stringify({ id_tramite: "0001", radicado: data.radicado, fecha_radicacion: data.fecha_radicacion }));
            history.push({ pathname: `/tramites-y-servicios/${props?.id}/procesar`, state: { id_tramite: "0001", radicado: data.radicado } });
        }
        if (!response.ok) {
            const message = `Ha ocurrido un error: ${response.status}`;
            throw new Error(message);
        }

    }

    const validationCheck = (idName, checkID) => {
        if (!document.querySelector(idName).checked) {
            checkID = false;
            document.querySelector(idName).parentElement.querySelector('.valCheck').classList.add('error'); //.classList.add('error');
        } else {
            checkID = true;
            document.querySelector(idName).parentElement.querySelector('.valCheck').classList.remove('error'); //.classList.add('error');
        }
    }


    const submitButton = (event) => {
        setSubmitFirst(true);
        validationCheck("#terminos-condiciones", terminos);
        validationCheck("#datos-personales", datos_personales);

        if (document.getElementById("datos-completos")) {
            if (document.getElementById("datos-completos").value === "false") {
                validate_datos_personales = false;
            }
        }
        var Form = document.querySelector('#tramite');

        Array.prototype.forEach.call(Form.elements, (element) => {
            if (element.name !== '') {
                if (document.getElementById(element.name)) {
                    if (document.getElementById(element.name).classList.contains('showFileInput')) {
                        if (!document.getElementById(element.name).classList.contains('valid')) {
                            if ((element.value !== '' && element.required)) {
                                validate_name_form = true;
                            } else {
                                validate_name_form = false;
                                if ((element.value !== '' && document.getElementById(element.name).classList.contains('valid')) ||
                                    (element.value === '' && !element.required)) {
                                    validate_name_form = true;
                                }
                            }
                        }

                    }
                    else {
                        if (!document.getElementById(element.name).classList.contains('valid')) {
                            validate_name_form = true;
                        }
                    }
                }
            }
        });

        // debugger;
        // console.log("**********");

        // console.log("**********");
        // Array.from(Form.elements).forEach(item => {console.log(item.id+ ': '+ item.checkValidity() + ': '+ item.value)});

        //Validar files
        if (Form.checkValidity()) {
            if (terminos === true && datos_personales === true && validate_name_form && validate_datos_personales) {
                //if(submitFirst){
                let extractSJSON = extractValuesJSON();
                let extractFilesJSON = extractFilesJson();
                //console.log(extractSJSON);

                //debugger;
                sendRequestForm(extractSJSON, extractFilesJSON);
                //}
            }
        }else{
            alert("Por favor valide campo diligenciado puede que no tenga errores en uno o más de los campos diligenciados");

            Array.from(Form.elements).forEach(item => {if(item.name){
                // console.log(item.id+ ': '+ item.checkValidity() + ': '+ item.value)
                if(!item.checkValidity()){
                    document.getElementById(item.id).scrollIntoView();
                }
            }});
        }
        event.preventDefault();
    }

    const extractValuesJSON = () => {
        const jsonExtract = {};
        
        // console.log('***************************');
        // console.log(elements);

        var Form = document.querySelector('#tramite');
        //Array.from(Form.elements)
        elements.map((_group) => {
            _group.fields.map(field => {
                if (field.field_type !== 'file') {
                    //console.log(`${field.field_name} ==== ${field.field_value}`);
                    jsonExtract[field.field_name] = Form.elements.namedItem(field.field_id).value;//field.field_value;
                }
                return;
            })
            return;
        });
        return jsonExtract;
    }

    const extractFilesJson = () => {
        const filesExtract = {};
        debugger

        elements.map((_group) => {
            _group.fields.map(field => {
                if (field.field_type === 'file') {
                    if(document.querySelector(`[id^='${field.field_id}-']`)){
                        filesExtract[field.field_name] = document.querySelector(`[id^='${field.field_id}-']`).value;
                    }
                }
                return true;
            })
            return true;
        });
        return filesExtract;
    }

    const handleChange = (id, event) => {
        const newElements = elements;
        const newGroups = [];
        newElements.map((group) => {
            const newGroup = { ...group };
            newGroup.fields.forEach((field, i) => {
                const { field_type, field_id } = field;
                if (id === field_id) {
                    switch (field_type) {
                        case 'checkbox':
                            field['field_value'] = event.target.checked;
                            break;

                        default:
                            field['field_value'] = event.target.value;
                            break;
                    }
                }
            });
            newGroups.push(newGroup);
            return true;
        });
        setElements(newGroups);
    }

    const cargarSecciones = () => {
        let inputDraw = 0;
        return elements ? elements.map((group, i) => {
            inputDraw = inputDraw + i;
            return <LoadForm key={i.toString()} dinamyc_form={group} index={i} firstState={submitFirst} />
        }) : null;
    }

    let terminos = true;
    let datos_personales = true;
    //let validate_recaptcha = true;
    let validate_name_form = false;
    let validate_datos_personales = true;


    const validateUrlFUGA=()=>{
        //debugger;
        let urlCurrent = window.document.location.pathname;
        return urlCurrent.includes("10004");
    }
    // const propsValid = (props) => {
    //     Object.values(props).every(prop => prop !== undefined)
    // }
    useEffect(() => {
        setElements(props?.dinamyc_form);
    },[props]);
    return (

            <FormContext.Provider value={{ handleChange }}>
                {(props?.view_data_profile).toLowerCase() === 'true' ?
                    <app-data-profile-scrd 
                        url_redirect_customize={`${process.env.REACT_APP_URL_AUTH}`}
                        referrer='barra-gov-co-wcmp'
                        url_login={`${process.env.REACT_APP_URL_AUTH}`}
                        view_data_profile={props.view_data_profile}
                        user_id=""
                    /> : <></>
                }
                <form id="tramite">
                    {cargarSecciones()}
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: props.descripcion_final }}></p>
                    </div>
                    <div className="mt-4 mb-5">
                        <div className="">
                            <h2 className="h3-tramite mb-4">
                                Aviso de privacidad y autorización para el tratamiento de datos personales
                            </h2>
                            <label className="checkbox-govco">
                                <input type="checkbox" id="datos-personales"
                                    onChange={() => validationCheck("#datos-personales", datos_personales)} required />
                                <label className="valCheck" htmlFor="datos-personales">
                                    <a target="_blank" rel="noopener noreferrer" href={validateUrlFUGA?"https://www.fuga.gov.co/transparencia-y-acceso-a-la-informacion-publica/normativa/politicas-de-seguridad-de-la-informacion-del-sitio":"https://www.culturarecreacionydeporte.gov.co/sites/default/files/autorizacion.pdf"}>Autorizo el tratamiento de mis datos personales</a>
                                </label>
                            </label>
                            <label className="checkbox-govco">
                                <input type="checkbox" id="terminos-condiciones"
                                    onChange={() => validationCheck("#terminos-condiciones", terminos)} required />
                                <label className="valCheck" htmlFor="terminos-condiciones">
                                    <a target="_blank" rel="noopener noreferrer" href={validateUrlFUGA?"https://fuga.gov.co/sites/default/files/2022-02/Propuesta_terminos_y_condiciones_web_fuga.pdf":"https://www.culturarecreacionydeporte.gov.co/es/politica-de-privacidad"}>Acepto términos y condiciones</a>
                                </label>
                            </label>
                        </div>
                        <div className="pb-4">
                            <ReCAPTCHAInput />
                        </div>
                        {/* <div className="alert gov-co-alert-error">
                            <span className="govco-icon govco-icon-tramite-error"></span>
                            Los datos ingresados contienen errores, por favor verifica nuevamente.
                        </div> */}
                        {/* <!-- Boton importancia alta govco --> */}
                        <button type="button" id="tramite-radicar" className="btn btn-round btn-high boton-inicio" onClick={(e) => submitButton(e)}>Radicar</button>
                        {/* <!-- Boton importancia media govco --> */}
                        {/* <button type="button" className="btn btn-round btn-middle ml-2 ">Vista previa</button> */}
                        <Link to={`/tramites-y-servicios/${props.id}`} className="btn btn-round btn-middle ml-4 boton-inicio">Cancelar</Link>
                    </div>
                </form>
            </FormContext.Provider>
            
    );
}

class TwoStepContainer extends Component {
    constructor(props) {
        super(props);
        //console.log(props)
        window.scrollTo(0, 0);
        this.state = {
            loa:  document.getElementById("userKeycloak") ? document.getElementById("userKeycloak").getAttribute('data-loa'): "1",
            elements: null,
            loaValidation: true
        }
        // setTimeout(() => { 
        //     debugger;
        //     console.log(props.loa);
        //     if(props.loa){
        //         this.setState({ loa: document.getElementById("userKeycloak").getAttribute('data-loa')});
        //     }
        //     if(props.loa){
        //         this.setState({loaValidation: (props.loa).includes(this.state.loa)});
        //     }
        //     if(!this.state.loaValidation){
        //         const mLOA = document.querySelector(`.bd-example-modal-lg-${props.id_tramite}`);
        //         mLOA.style.display = "flex";
        //     }
        // }, 6000);

    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.elements);
    }
    propsValid = (props) => {
        Object.values(props).every(prop => prop !== undefined)
    }


    render() {
        return (
            <>
                <Breadcrumbs id_tramite={this.props?.id_tramite} nombre_tramite={this.props?.title} nombre_action={'Radicar'} />
                <main>
                    <TabsProgresoTramite currentStep={2} prevUrl={`/tramites-y-servicios/${this.props?.id_tramite}`}></TabsProgresoTramite>

                    <h1 className="h1-tramite mt-5">
                        {this.props.title || <Skeleton />}
                    </h1>
                    {this.props?.description && this.state?.loaValidation ? <div dangerouslySetInnerHTML={{ __html: this.props?.description }}></div> : <Skeleton count={10}/>}


                    {/* {localStorage.getItem('userName') ? <Secured></Secured> : <></>} */}

                    {(this.state.loaValidation)?
                        <DynamicForm
                            dinamyc_form={this.props?.formulario?.groups}
                            descripcion_final={this.props?.formulario?.descripcion_final}
                            id={this.props?.id_tramite}
                            usuario={this.props?.usuario}
                            view_data_profile={this.props?.view_data_profile}
                            tipo_solicitud={this.props?.tipo_solicitud}>
                        </DynamicForm>
                        : <><br/><Skeleton count={10}/></>
                    }
                </main>
                <button id="modal-loa" type="button" className="d-none" data-toggle="modal" data-target={`.bd-example-modal-lg-${this.props?.id_tramite}`}>modal LOA</button>
                <PopUpLOA index={this.props?.id_tramite} url={this.props?.url}></PopUpLOA>
            </>
    );}

    componentDidMount(){
        setTimeout(() => { 
            // debugger;
            // console.log(this.props)
            if(this.props.loa){
                this.setState({ loa: document.getElementById("userKeycloak").getAttribute('data-loa')});
            }
            if(this.props.loa){
                this.setState({loaValidation: (this.props.loa).includes(this.state.loa)});
            }
            if(!this.state.loaValidation){
                const mLOA = document.querySelector(`.bd-example-modal-lg-${this.props.id_tramite}`);
                mLOA.style.display = "flex";
                //this.setState({loaValidation: (this.props.loa).includes(this.state.loa)});
            }
        }, 6000);
    }
    
}

export default TwoStepContainer;