import React, { Component } from 'react';
class ImageLogo extends Component {
    constructor(props) {
        super(props);
        //debugger;
        this.state = {
            imageLogo:  this.validateUrlFUGA()
        }
    }
    
    validateUrlFUGA=()=>{
        //debugger;
        let urlCurrent = window.document.location.pathname;
        return urlCurrent.includes("10004");
    }
    
    render() {
        const logoSCRD = "https://www.culturarecreacionydeporte.gov.co/sites/default/files/2022-08/Logo-secretaria-cultura-recreacion-deporte-morado.svg";
        const logoFUGA = "https://www.fuga.gov.co/sites/default/files/inline-images/logo-fuga-bogota.png";
        const urlSCRD = "https://www.culturarecreacionydeporte.gov.co/";
        const urlFUGA = "https://fuga.gov.co/";
        return (
            <>
                <a className="d-flex justify-content-left" target="_blank" rel="noopener noreferrer" href={this.validateUrlFUGA()?urlFUGA:urlSCRD}>
                    <img src={this.state.imageLogo?logoFUGA:logoSCRD} className="imgLogo"
                        alt="Logo de la Secretaría de Cultura, Recreación y Deporte" />
                </a>
            </>
        );
    }
}

export default ImageLogo;