import React, {Component}from 'react';
import Breadcrumbs from "../components/Breadcrumbs";
import TabsProgresoTramite from "../components/TabsProgresoTramite";
import { useState } from 'react';
import Element from '../components/Element';
import { FormContext } from '../components/FormContext';
//import AvisoPrivacidad from '../components/AvisoPrivacidad';
import { Link } from 'react-router-dom';
// import TableResults from '../components/TableResults';
//import Secured from '../components-keycloak/Secured';
import ReCAPTCHAInput from '../components/ReCAPTCHAInput';

let inputDraw = 0;

function LoadForm(props) {
    const [elements] = useState(props.dinamyc_form);
    const { fields, name_group, description_group } = elements ?? {}
    const classFirst = "h2-tramite "+ (props.index < 1 ? 'mt-5 ': '');

    const validatePattern = (field) => {
        if(props.firstState){
            let regex = new RegExp(field.field_attributes.pattern);
            let valPattern = !regex.test(field.field_value);
            let attributes = document.getElementById(field.field_id);
            let valRequired = (field.field_value === '');
            let valMaxLength = field.field_attributes?.maxlength ? ((field.field_value).length > field.field_attributes?.maxlength): false;
            let valMinLength = field.field_attributes?.minlength ? ((field.field_value).length < field.field_attributes?.minlength): false;

            if(valPattern || valRequired){ //Class Input 
                attributes.classList.add("invalid")
                attributes.classList.remove("valid");
            }else{
                attributes.classList.add("valid");
                attributes.classList.remove("invalid");
            } ;

            return (valRequired) ? "Este campo es requerido" :  // Requerido
                valMinLength ? field.field_error_validaciones.minlength : // Min Length 
                    valMaxLength ? field.field_error_validaciones.maxlength : // Max Length 
                        valPattern ? field.field_error_validaciones.pattern:''; // Pattern
        }
    }
    return (
        <>
            <h2 className={classFirst}>
                {name_group}
            </h2>
            {description_group ? <div className="mt-4" dangerouslySetInnerHTML={{ __html: description_group }}></div>:null }
            <div className="row mt-4">
                {fields ? fields.map((field, i) => <Element key={i.toString()} field={field} validateError={validatePattern(field)} />): null}
            </div>
        </>
    );
}


function DynamicForm(props) {
    const [elements, setElements] = useState(props.dinamyc_form);
    const [submitFirst, setSubmitFirst] = useState(false);
    const [tableElements, setTableElements] = useState(false);
    //let history = useHistory();

    const sendRequestForm = async (fieldsJSON) =>{
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ 
        //         "id_tramite": `${props.id}`,
        //         "tipo_tramite": "servcio", // esal // servicio //bic
        //         "usuario": "1",
        //         "backend": "esal",
        //         "estado": "activo",
        //         "objeto_negocio": JSON.stringify(fieldsJSON),
        //         "anexos": "[]"
        //     })
        // };
        // debugger;
        // console.log(requestOptions);

        const response = await fetch(`${process.env.REACT_APP_URL_ADMIN}/api/v1/tramites/datos-basicos-sin-anexos/?search=${fieldsJSON.id_radicado}`);
        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setTableElements(data);
            localStorage.setItem('resultConsult', JSON.stringify(data));
            window.location.reload();
        }
    }

    const submitButton = (event) => {
        event.preventDefault();
        setSubmitFirst(true);
        var Form = document.querySelector('#tramite');
        if (Form.checkValidity()) {           
            //if(submitFirst){
                let extractSJSON = extractValuesJSON();
                console.log(extractSJSON);
                sendRequestForm(extractSJSON);
            //}
        }
    }

    const extractValuesJSON = () =>{
        const jsonExtract = {};
        elements.map((_group)=>{
            return _group.fields.map(field => {
                jsonExtract[field.field_name] = field.field_value;
                return true;
            })            
        });
        return jsonExtract;
    }

    const handleChange = (id, event) => {      
        const newElements = elements;
        const newGroups = [];
        newElements.map((group)=>{
            const newGroup = {...group};
            newGroup.fields.forEach((field, i) => {
                const { field_type, field_id } = field;
                if (id === field_id) {
                    switch (field_type) {
                        case 'checkbox':
                        field['field_value'] = event.target.checked;
                        break;
            
                        default:
                        field['field_value'] = event.target.value;
                        break;
                    }
                }
            });
            newGroups.push(newGroup);
            return true;
        });
        setElements(newGroups);
    }

    const cargarSecciones = () =>{
        return elements ? elements.map((group, i) =>{
            inputDraw = inputDraw + i;
            return <><LoadForm key={i.toString()} dinamyc_form={group} index={i} firstState={submitFirst} /></>
        }): null;
    }
    return (
      <FormContext.Provider value={{ handleChange }}>
        <form id="tramite">
            {cargarSecciones()}
            <ReCAPTCHAInput />
            <div className="mt-2 mb-5">
                <button type="submit" className="btn btn-round btn-high boton-inicio" onClick={(e) => submitButton(e)}>Consultar</button>
                <Link to={`/tramites-y-servicios/${props.id}`} className="btn btn-round btn-middle ml-4 boton-inicio">Volver</Link>              
            </div>
        </form>
      </FormContext.Provider>
    );
  }


class FourStepContainerAlt extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0,0);
    }
    render(){
        const urlAtras = `/tramites-y-servicios/${this.props.id_tramite}`;
        return (
            <>
                <Breadcrumbs id_tramite={this.props.id_tramite} nombre_tramite={this.props.title} nombre_action={'Consultar'} />
                <main>
                    <TabsProgresoTramite currentStep={4} prevUrl={urlAtras}></TabsProgresoTramite>
                    <DynamicForm dinamyc_form={this.props.formulario.groups} id={this.props.id_tramite} />
                    {/* <TableResults></TableResults> */}
                </main>
            </>

        );
    }
}

export default FourStepContainerAlt;