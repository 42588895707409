/* eslint no-eval: 0 */
import React, { useContext } from 'react'
import { useEffect } from 'react';
import { FormContext } from '../../components/FormContext';
const Checkbox = ({ field_id, field_label, field_value, conditional }) => {
    const { handleChange } = useContext(FormContext)
    useEffect(() => {
        if(conditional !== undefined){
            if(conditional !== ''){
                eval(conditional);
            }
        }
    });
    return (
        <div className="mb-3 form-check" id={'group-field-'+field_id}>
            <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={field_value}
                onChange={event => handleChange(field_id, event)}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">{field_label}</label>
        </div>
    )
}

export default Checkbox
