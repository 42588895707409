/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React from 'react';
//import tramites from '../../assets/tramites.json';
import TwoStepContainer from '../steps/twoStepContainer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import TramitesService from "../../services/TramitesService";
///import UserService from "../../services/UserService";
//import $ from 'jquery';

const useScript = scriptCode => {
    useEffect(() => {

        const script = document.createElement('script');
        script.innerHTML = scriptCode;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [scriptCode]);
};

const Formulario = () => {

    const [tramite, setTramite] = useState({})

    const getTramite = async () => {
        const idTramite = id.replace("t", "");
        const tramiteResp = await TramitesService?.getTramite(idTramite);
        const tramiteLoad = TramitesService?.replaceProp(tramiteResp);
        //console.log(tramiteLoad.tipo_solicitud);
        
        setTramite(tramiteLoad);

        if (tramiteLoad?.tramite !== null || undefined) {
            document.title = `SCRD | ${tramiteLoad?.titulo ?? ""}`;
        }
    };
    
    const { id } = useParams();
    const state = {
        tramite: tramite
    };
    useEffect(() => {
        getTramite();
        //console.log(state?.tramite?.tipo_solicitud);
    }, []); 

    const stepEmsablador = (
        state.tramite ?
            <>
                {JSON.stringify(state?.tramite) !== JSON.stringify({})?
                    <TwoStepContainer
                        id_tramite={id}
                        title={state?.tramite?.titulo}
                        description={state?.tramite?.descripcion_corta}
                        formulario={state?.tramite?.form_tramite}
                        view_data_profile={state?.tramite?.ver_datos_basicos_form} // boolean 
                        usuario={'123'}
                        tipo_solicitud={state?.tramite?.tipo_solicitud}
                        loa={state?.tramite?.loa_permitido}
                    />
                :<></>
                }
            </> :
            <>
                <h1>Página no encontrada 404</h1>
                <h2 className="h1-tramite mt-5">
                    El trámite {id} no ha sido registrado en el sistema
                </h2>
            </>
    );
    useScript('$(document).ready(function(){' + state?.tramite?.conditionales_js + '}); $("select").selectpicker("refresh");');
    return (<>{
        stepEmsablador
    }</>);
}

export default Formulario;
