import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DudasContacto from "./components/DudasContacto";
import Experiencia from "./components/Experiencia";
import Tramite from './shared/Tramite';
import Formulario from './shared/Formulario';
import Procesar from './shared/Procesar';
import Logout from './components-keycloak/Logout';
import SecuredRedirect from './components-keycloak/SecuredRedirect';
import PersonlizarRedirect from './components-keycloak/PersonlizarRedirect';
import ConsultaAlt from './shared/ConsultaAlt';
//import PopUpTramite from './components/PopUpTramite'
import Tutoriales from './components/Tutoriales';

class AppRouter extends Component {

    render() {
        return (
            <>
            {/* 60794 - Registro y sello de libros de actas - Certificado inspección, vigilancia y control. */}
            {/* 61371 - Solicitud de certificado de existencia y representación legal para los organismos deportivos y/o recreativos vinculados al Sistema Nacional del Deporte */}
            {/* 61370 - Certificación de existencia y representación legal ESAL  */}
            {/* 63278 - Declaratoria, revocatoria o cambio de categoría de un bien de interés cultural del ámbito Distrital */}

                <div className="row">
                    <div className="col-lg-8 m-auto">
                        <Router>
                            <Switch>
                                <Route exact path="/logout">
                                    <Logout></Logout>
                                </Route>    
                                <Route exact path="/secured">
                                    <SecuredRedirect></SecuredRedirect>
                                </Route>       
                                <Route exact path="/personalizar">
                                    <PersonlizarRedirect></PersonlizarRedirect>
                                </Route>                                        
                                <Route exact path="/tramites-y-servicios/:id" >
                                    <Tramite></Tramite>
                                </Route>
                                <Route exact path="/tramites-y-servicios/:id/radicar">
                                    <Formulario></Formulario>
                                </Route>
                                <Route exact path="/tramites-y-servicios/:id/procesar">
                                    <Procesar></Procesar>
                                </Route>  
                                <Route exact path="/tramites-y-servicios/:id/consultar">
                                    <ConsultaAlt></ConsultaAlt>
                                </Route>                      
                                <Route exact path="/tramites-y-servicios/:id/respuesta">
                                    {/* <Respuesta></Respuesta> */}
                                    Respuesta
                                </Route>                      
                            </Switch>
                        </Router>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-3 px-0">
                        <div className="gov-co-servi">
                            <Router>
                                <Switch>
                                    <Route path="/tramites-y-servicios/:id"
                                        render={(routerProps) =>
                                        <>
                                            <Tutoriales 
                                                {...routerProps}

                                            />
                                            <DudasContacto 
                                                {...routerProps} 

                                            />
                                            <Experiencia
                                                {...routerProps}
                                                usuario={localStorage.getItem('userName')}
                                            ></Experiencia>
                                            <a href={`${routerProps.match.url}`} className="btn btn-round btn-middle boton-inicio mb-4 w-100">VOLVER A INICIO DEL TRÁMITE</a>
                                        </>}
                                    />   
                                </Switch>
                            </Router>                                        
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AppRouter;