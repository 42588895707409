import React, {Component}from 'react';
import OptionCard from '../components/OptionCard';
import TabsProgresoTramite from "../components/TabsProgresoTramite";
import Breadcrumbs from "../components/Breadcrumbs";
import PopUpLOA from '../components/PopUpLOA';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class OneStepContainer extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0,0);
        this.state = {
            loa:  document.getElementById("userKeycloak") ? document.getElementById("userKeycloak").getAttribute('data-loa'): "1",
            elements: null,
            loaValidation: false
        }        
    }

    render_action_links(actions, id){
        if(actions){
            return actions?.map((item, index) => {
                return (item.state === 'active')?
                (
                    <div className="col-lg-4 d-flex" key={index}>
                        <OptionCard 
                            index={index}
                            titulo={item.nombre} 
                            descripcion={item.descripcion} 
                            loa_permitido={this.props.loa}
                            url={`/tramites-y-servicios/${this.props.id_tramite}/${item.action_url}`}>
                        </OptionCard>
                    </div>
                ):<h1>Cargando...</h1>;
            });
        } 
        return <></>;
    }
    render(){
        return (
            <> 
                <Breadcrumbs id_tramite={this.props?.id_tramite} nombre_tramite={''} nombre_action={this.props?.title} />
                {/* <!-- PRIMER PASO --> */}
                <main>
                    <TabsProgresoTramite currentStep={2} prevUrl={`/tramites-y-servicios/${this.props?.id_tramite}`}></TabsProgresoTramite>
                    <h1 className="h1-tramite mt-5">
                        {this.props?.title || <Skeleton count={2}/>}
                    </h1>
                    {this.props?.description ? <div className="pb-3" dangerouslySetInnerHTML={{ __html: this.props?.description}}></div> : <Skeleton count={10}/>}
                    <h4 className="mt-1">{this.props?.tipo_solicitud ? `Acciones del ${this.props?.tipo_solicitud ? this.props?.tipo_solicitud : 'trámite'}` : <Skeleton />}</h4>
                    <div className="row mb-5 mt-3">
                        {this.render_action_links(this.props?.action_links, this.props?.id_tramite)}
                    </div>
                </main>
                <button id="modal-loa" type="button" className="d-none" data-toggle="modal" data-target={`.bd-example-modal-lg-${this.props?.id_tramite}`}>modal LOA</button>
                <PopUpLOA index={this.props?.id_tramite} url={this.props?.url}></PopUpLOA>
            </>

        );
    }

    // componentDidUpdate(){
    //     //this.setState({loaValidation: (this.props?.loa).includes(this.state.loa)});
    //     //setTimeout(() => {       
    //         debugger;    
    //         if(document.getElementById("userKeycloak")){
    //             if(this.props?.loa){
    //                 this.setState({loaValidation: (this.props?.loa).includes(this.state.loa)});
    //             }
    //             //alert(this.state?.loaValidation);
    //             if(!this.state?.loaValidation){
    //                 const mLOA = document.querySelector(`.bd-example-modal-lg-${this.props?.id_tramite}`);
    //                 mLOA.style.display = "flex";
    //             }
    //         }
    //     //}, 4000);
    // }

    componentDidMount(){
        setTimeout(() => {       
            if(document.getElementById("userKeycloak")){
                // if(this.props?.loa){
                //     this.setState({ loa: document.getElementById("userKeycloak").getAttribute('data-loa')});
                // }
                // if(this.props?.loa){
                //     this.setState({loaValidation: (this.props?.loa).includes(this.state.loa)});
                // }
                //debugger;
                var validationloa = (document.getElementById('userKeycloak').innerText.toLowerCase()).includes('@'); // validation with name 

                //if(!this.state?.loaValidation){
                if(validationloa){
                    const mLOA = document.querySelector(`.bd-example-modal-lg-${this.props?.id_tramite}`);
                    mLOA.style.display = "flex";
                }
            }
        }, 6000);
    }
}

export default OneStepContainer;
