import React, { Component } from 'react';
//import { ReCAPTCHA } from "react-google-recaptcha";

class ReCAPTCHAInput extends Component {
    render() {
        const SITE_KEY = "6LcS6zocAAAAAKkapsCxZ2IoQYm8GCnKfN8c-1i-";
        return (
            // <ReCAPTCHA
            //     ref={(r) => this.recaptcha = r}
            //     sitekey={SITE_KEY}
            //     grecaptcha={grecaptchaObject}
            // /> 
            <recaptcha-scrd data-sitekey={SITE_KEY}></recaptcha-scrd>
        );
    }
}

export default ReCAPTCHAInput;