import React, { Component } from 'react';

class TableResults extends Component {

    //return a promise that resolves with a File instance
    urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }

    downloadBase64File =  (base64Data, id_radicado) =>{
        const linkSource = `data:application/pdf;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = `${id_radicado}.pdf`;
        downloadLink.click();
   }

    renderResult = (data) =>{
        return data.map( result => {
            return (
            <tr>
                <td className="state-success">
                    <span>{result.estado}</span>
                </td>                                
                <td>{result.radicado}</td>
                <td>{result.fecha_radicacion}</td>
                    
                    <td className="table-actions">
                        <div className="links-actions">
                        {/* result.estado === 'finalizado' ||  */}
                        {result.archivo_base64}
                        {
                            (result.respuesta_base64 !== '')?
                            <a target="_blank" rel="noopener noreferrer" className="action-table" href={() => false} onClick={()=> this.downloadBase64File(result.respuesta_base64, result.radicado)}>Descargar</a>:<>---</>
                        }
                        </div>
                    </td>
            </tr>)
        });
    }

    comebackConsult = (event) => {
        event.preventDefault();
        localStorage.removeItem('resultConsult');
        window.location.reload();
    }
    
    render() {
        const dataResults = JSON.parse(this.props.data);
        return (
            <>
            {/*<!-- DataTable --> */}
                <div className="table-pagination-govco my-5">
                    <div className="title-table">
                        <h4>Resultados de la consulta</h4>
                    </div>
                    <table id="DataTables_Table_example" className="dataTable table-govco table-responsive-sm table-responsive-md w-100">
                        <thead>
                            <tr>
                                <th className="table-state">Estado</th>
                                <th>Número del radicado</th>
                                <th>Fecha de radicado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderResult(dataResults.results)}
                        </tbody>
                    </table>
                    <div className="content-footer-table">
                        <div className="cotent-ctrls">
                            <button className="btn btn-round btn-high boton-inicio"  onClick={(e) => this.comebackConsult(e)}>Nueva Consulta</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

TableResults.propTypes = {

};

export default TableResults;