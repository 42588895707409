import React, { Component } from 'react';

class Experiencia extends Component {
    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        // console.log(this.props);
        this.state = {
            experiencia: null,
            urlEndpoint: `${process.env.REACT_APP_URL_ADMIN}/api/v1/experiencia/usuarios/`,
            id_tramite: `${id.toString().replace('t', '')}` ?? '0'
        }
        //localStorage.setItem('id_tramite',id);
    }

    render() {
        return (
            <>
                <component-qualify-procedure 
                    endpoint={this.state.urlEndpoint}
                    id_tramite={this.state.id_tramite}
                    usuario={this.props.usuario}
                    className="d-flex justify-content-end">
                </component-qualify-procedure>
            </>
        );
    }
}

export default Experiencia;