import React, { Component } from 'react';
//import UserInfo from './UserInfo';
// import Logout from './Logout';
// import QueryAPI from './QueryAPI';
import Keycloak from 'keycloak-js';
class Secured extends Component {

  constructor(props) {
    super(props);
    this.state = { keycloak: null, authenticated: false };
  }

  componentWillMount() {
    const keycloak = Keycloak('/keycloak.json');
    keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).then(authenticated => {
      this.setState({ keycloak: keycloak, authenticated: authenticated })
    });
  }

  logout() {
    this.state.keycloak.logout();
    localStorage.removeItem('userName');
  }

  render() {
    console.log(this.state.keycloak);
    if(this.state.keycloak) {
      if(this.state.authenticated) {
        return (
        <div>
          {/* <UserInfo keycloak={this.state.keycloak}/> */}
          {/* <QueryAPI keycloak={this.state.keycloak} /> */}
          {/* <Logout logout={() => this.logout()} /> */}
        </div>
        );
      } else return (<div>Unable to authenticate!</div>)
    }
    return (
      <div>
        <p>Initializing Keycloak...</p>
      </div>
    );
  }
}

export default Secured;
