import React, { useContext } from 'react'
import { useEffect } from 'react';
import { FormContext } from '../../components/FormContext';
// import AddressBase64 from 'react-file-base64';
// import { useState } from 'react';
// import { FormContext } from '../../components/FormContext';

const Address = ({ 
    field_id, field_name, field_label, field_required, field_class_wrapper, aria_describedby,
    field_placeholder, field_attributes, field_type, field_value, validateError,

    //files
    multiple, mime_types, max_size_mb, text_tipo_documental, info_adiccional, disabled, descripcion, activar_observaciones, maxlength_comentario,

    //conditional
    conditional
 }) => {

    const { handleChange } = useContext(FormContext);
    const componentInstance = document.querySelector(`#${field_id}`);
    
    useEffect(() => {
        if(componentInstance?.hasAttribute("onChange")){
            componentInstance.setAttribute("onChange", (event) => {handleChange(field_id, event)});
        }
        if(componentInstance && field_required==='true'){
            if(componentInstance.value !== ''){
                componentInstance.classList.remove('invalid');
                componentInstance.classList.add('valid');
            }else{
                componentInstance.classList.add('invalid');
                componentInstance.classList.remove('valid');
            }
        }
    });

    //debugger;
    return (
        <>
            <div className={field_class_wrapper} id={'group-field-'+field_id}>
                <div className="mb-3">
                    <component-address
                        _id={field_id}
                        _name={field_name}
                        _label={field_label}
                        _aria_describedby={aria_describedby}
                        _placeholder={field_placeholder}
                        _required={field_required}

                        is_address_colombia="true"
                        is_distrital="true"
                    />
                    {validateError && field_required==='true' && (document.querySelector(`#${field_id}`)?.value === '') ?
                     <p className='error'><small className="form-text invalid">{validateError}</small></p>
                     : ''
                    }
                </div>
            </div> 
        </>
    )
}

export default Address
