import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import AppRouter from "./AppRouter";
import Footer from "./components/Footer";
import ImageLogo from "./components/ImageLogo";
// import Consulta from './shared/Consulta';
// import TableResults from "./components/TableResults";
import UserService from "../services/UserService";


class MainWithoutLogin extends Component {
    constructor(props) {
        super(props);
        const host = window.location.host;
        this.state = {
            urlLogin: host + "/auth/realms/SCRD/account/",
            urlProfile: host + "/auth/realms/SCRD/account/",
            userName: ''
        };
        this.state = { value: "", store: "" };
    }
    componentDidMount() {
      window.addEventListener("storage", e =>{
        const user = sessionStorage.getItem('userName');
        this.setState({ userName: user})
      });
    }

    logout = () => {
        //debugger;
        this.props.history.push('/')
        this.props.keycloak.logout();
        sessionStorage.removeItem('userName');
    }

    handleClick(){
        alert('Se hizo click');
    }

    goTo = () => console.log('I got called');
    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
      }

    render(){
        const onLoadMethod = "check-sso"; //"login-required"  or "check-sso"
        UserService.setOnloadKeycloak(onLoadMethod);
        return (
            <>
                <govco-header
                    ishome="true" isbeta="false" 
                    usuario = {UserService.getUsername()}

                    url_login = {process.env.REACT_APP_URL_AUTH}
                    auth_active="true"
                    search_active="false"

                    client_id="barra-gov-co-wcmp"
                    onload_keycloak= {onLoadMethod} //"login-required"  or "check-sso" 
                >
                </govco-header>   
                <header>
                    <div className="container">
                        <ImageLogo></ImageLogo>
                    </div>
                </header>

                <section className="body">
                    <govco-accesibility-bar></govco-accesibility-bar>

                    <div className="container">
                        <h1>Esta es un prueba de login</h1>                  
                    </div>
                </section>
                <component-qualify-procedure 
                    endpoint={this.state.urlEndpoint}
                    id_tramite={this.state.id_tramite}
                    usuario={UserService.getUsername()}
                    fullscreen='true'
                    className="d-flex justify-content-end">
                </component-qualify-procedure>
                <govco-back-to-top></govco-back-to-top>
                <Footer></Footer>
            </>
        );
    }
}

export default MainWithoutLogin;