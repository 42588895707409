import React, {Component} from 'react';
class tabsProgresoTramite extends Component {
    
    // constructor(props) {
    //     super(props);
    // }

    colorBullet(numBullet){
        return this.props.currentStep === numBullet ?'active': this.props.currentStep > numBullet ? 'after-active' : '';
    }
    render() {
        const currentStep = this.props.currentStep;
        //const prevUrl = this.props.prevUrl;
        return (
            <div className="gov-co-advance">
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                        aria-valuemax="100" style={{width: (currentStep !== 4 ? (((currentStep-1)*25)+ 3): 100)+"%"}}></div>
                </div>
                <div className="navs-link-advance justify-content-between">
                    <a className={'nav-link-advance after-active'} href="!#">
                        <span>1</span> Inicio
                    </a>
                    <a className={'nav-link-advance ' + this.colorBullet(2)} value="2" href='!#' >
                        <span>2</span> Hago mi solicitud
                    </a>
                    <a className={'nav-link-advance ' + this.colorBullet(3)} value="3" href='!#'>
                        <span>3</span> Procesan mi solicitud
                    </a>
                    <a className={'nav-link-advance ' + this.colorBullet(4)} value="4" href='!#'>
                        <span>4</span> Respuesta
                    </a>
                </div>
            </div>  
        );
    }
}
export default tabsProgresoTramite;
