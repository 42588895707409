/* eslint no-eval: 0 */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React from 'react';
//import tramites from '../../assets/tramites.json';
import ThreeStepContainer from '../steps/threeStepContainer';
import TramitesService from "../../services/TramitesService";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Procesar =  props => {
    const [tramite, setTramite] = useState({})
    const { id_tramite, radicado } = (props?.location && props?.location?.state) || {};
    const {id} = useParams();
    const state = {
        //tramite: tramites[`${id.toString().replace('t', '')}` ?? null],
        tramite: tramite,
        id_tramite: id_tramite,
        radicado: radicado
    };
    const getTramite = async () => {
        let isSubscribed = true;
        const idTramite = id.replace("t", "");
        const tramiteResp = await TramitesService?.getTramite(idTramite);
        const tramiteLoad = TramitesService?.replaceProp(tramiteResp);

        if (isSubscribed) {
            setTramite(tramiteLoad);
            if (tramiteLoad.tramite !== null || undefined) {
              document.title = `SCRD | ${tramiteLoad?.titulo ?? ""}`;
            }
          }
          return () => (isSubscribed = false)
    }

    useEffect(() => {
        getTramite();
    }, []);

    const stepEmsablador = ( state.tramite ?
        <ThreeStepContainer 
            id_tramite = {id} 
            title = {state?.tramite?.titulo} 
            description = {state?.tramite?.descripcion_corta}  
            action_links = {state?.tramite?.action_links}
            response_radicado = {state?.tramite?.radicado}
        />: 
        <>
            <h1>Página no encontrada 404</h1>
            <h2 className="h1-tramite mt-5">
                El trámite {id} no ha sido registrado en el sistema
            </h2>
        </>
    );

    return (<>{stepEmsablador}</>);
}

export default Procesar;