import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import './scss/index.scss';
import registerServiceWorker from './registerServiceWorker';
import Main from './layouts/Main';
import MainWithoutLogin from './layouts/MainWithoutLogin';
import { applyPolyfills, defineCustomElements } from 'scrd-components-base-ui/loader';


const routing = (
    <React.StrictMode>
      <Router>
        <>
          <Route exact path="/ejemplo-login" component={MainWithoutLogin} />
          <Route path="/" component={Main} />
        </>
      </Router>
    </React.StrictMode>
  )


 ReactDOM.render(routing, document.getElementById('root'));

//const renderApp = () => ReactDOM.render(routing, document.getElementById("root"));

registerServiceWorker();
applyPolyfills().then(() => {
  defineCustomElements();
});

//UserService.initKeycloak(renderApp);