import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: null
        }
    }

    async componentDidMount() {
        // POST request using fetch with async/await
        let token = localStorage.getItem('profileToken');
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token,
                'Accept': '*/*'
            },
            body: JSON.stringify({ 
                "id_parametro": "",
                "nombre_parametro": "información entidad"
            })
        };
        const response = await fetch(`${process.env.REACT_APP_URL_ADMIN}/api/v1/parametros/valores/search/`, requestOptions);
        const data = await response.json();
        
        this.setState({ footer: data });
    }

    validateUrlFUGA=()=>{
        let urlCurrent = window.document.location.pathname;
        return urlCurrent.includes("10004");
    }

    render() {
        return (
            <footer>
                {this.validateUrlFUGA()?            
                <govco-footer
                    color_fondo = "#5d4293"
                    titulo_entidad = "Fundación Gilberto Alzate Avendaño"
                    url_logo = "https://www.fuga.gov.co/sites/default/files/inline-images/logo-fuga-bogota%20%281%29_0.gif"
                    url_redirect_logo="https://fuga.gov.co/"
                    alt_url_logo = "Logo de Fundacion Gilberto Alzate Avendaño"
                    description_Logo = "Logo de Fundacion Gilberto Alzate Avendaño"
                    direccion = "Sede principal Dirección: Cra 3 # 10 - 27 Bogotá, D.C."
                    codigo_postal = ""
                    conmutador = "+57 (601) 432 04 10"
                    horario_atencion = "Abierto al público de Lunes a viernes de 8:00 a. m. a 5:30 p. m. jornada continua Sede Central y territorial Cundinamarca (Horario de atención temporal acatando las medidas y demás regulaciones de bioseguridad)"
                    correo_entidad = "atencionalciudadano@fuga.gov.co"
                    correo_notificaciones = "notificacionesjudiciales@fuga.gov.co"
                    nit="860.044.113-3 ©️Copyright 2022"
                    derechos="Todos los derechos reservados Gobierno de Colombia"
                    cuenta_twiter = "FUGABogota"
                    cuenta_instagram = "fundaciongilbertoalzate"
                    cuenta_facebook = "FUGABog"
                    politicas = "https://www.fuga.gov.co/transparencia-y-acceso-a-la-informacion-publica/normativa/politicas-de-seguridad-de-la-informacion-del-sitio"
                    mapa_sitio = "https://fuga.gov.co/sites/default/files/2022-02/Propuesta_terminos_y_condiciones_web_fuga.pdf"
                    terminos_condiciones = "https://fuga.gov.co/sites/default/files/2022-02/Propuesta_terminos_y_condiciones_web_fuga.pdf">
                </govco-footer>:
                <govco-footer
                    color_fondo = "#5d4293"
                    titulo_entidad = "Secretaría de Cultura, Recreación y Deporte"
                    url_logo = "https://www.culturarecreacionydeporte.gov.co/sites/default/files/2022-08/Logo-secretaria-cultura-recreacion-deporte-morado.svg"
                    url_redirect_logo="https://www.culturarecreacionydeporte.gov.co/"
                    alt_url_logo = "Logo de la Secretaría de Cultura, Recreación y Deporte"
                    description_Logo = "Logo de la Secretaría de Cultura, Recreación y Deporte"
                    direccion = "Cra 8 # 9 - 83, Bogotá - Colombia"
                    codigo_postal = "111711"
                    conmutador = "(601) 327 48 50"
                    horario_atencion = "Lunes a viernes 7:30 a.m. a 4:30 p.m."
                    correo_entidad = "correspondencia.externa@scrd.gov.co"
                    cuenta_twiter = "CulturaenBta"
                    cuenta_instagram = "culturaenbta"
                    cuenta_facebook = "CulturaenBogota"
                    politicas = "https://www.culturarecreacionydeporte.gov.co/es/politica-de-privacidad"
                    mapa_sitio = "https://www.culturarecreacionydeporte.gov.co/sitemap"
                    terminos_condiciones = "#">
                </govco-footer>}
            </footer>
        );
    }
}

export default Footer;