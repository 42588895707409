import Keycloak from "keycloak-js";

const _kc = new Keycloak(
  {
    "realm": "SCRD",
    "url": `${process.env.REACT_APP_URL_AUTH}`,
    "auth-server-url": `${process.env.REACT_APP_URL_AUTH}`,
    "ssl-required": "external",
    "resource": "barra-gov-co-wcmp",
    "clientId": "barra-gov-co-wcmp",
    "public-client": true,
    "confidential-port": 0
  });//'/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  // keycloak.init({ onLoad: 'login-required' }).then(function(authenticated) {
  //   alert(authenticated ? 'authenticated' : 'not authenticated');
  //   }).catch(function() {
  //       alert('failed to initialize');
  //   });   
  //_kc[auth-server-url] = process.env.REACT_APP_URL_AUTH;

  _kc.init({
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
  .then((authenticated) => {
      //alert(authenticated ? 'authenticated' : 'not authenticated');
      // if (authenticated) {
      onAuthenticatedCallback();
      //alert(UserService.isLoggedIn());
      // } else {
      //   doLogin();
      // }
    }).catch(function() {
      console.log('failed to initialize');
    }); 
  };

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getIDUser = () => {
  console.log(_kc);
  return _kc.tokenParsed?.sub;
}
const getEmail = () => _kc.idTokenParsed?.email;

const setOnloadKeycloak = (onload) => _kc.init.onLoad = onload;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getEmail,
  getUsername,
  hasRole,
  setOnloadKeycloak,
  getIDUser
};

export default UserService;
