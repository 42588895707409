/* eslint no-eval: 0 */
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { FormContext } from '../../components/FormContext';

const Textarea = ({ 
    field_id, field_name, field_label, field_required, field_class_wrapper, 
    field_placeholder, field_attributes, field_type, field_value, aria_describedby, tooltip_message, validateError, 

    conditional
 }) => {

    const { handleChange } = useContext(FormContext)
    const [TextareaFirstLoad, setTextareaFirstLoadState] = useState(0);

    useEffect(() => {
        var attributes = document.querySelector(`#${field_id}`);
            
        if(field_attributes){ // Incluir attributos dinamicos listados en la JSON propiedad 'field_attributes'
            if(attributes){
                var keys = Object.keys(field_attributes);
                keys.map(
                    key =>{
                        attributes.setAttribute(key, field_attributes[key]);
                        return true;
                    }
                )
            }
        }
        if(conditional !== undefined){
            if(conditional !== ''){
                eval(conditional);
            }
        }
        if(tooltip_message){
            attributes.setAttribute('data-toggle', "tooltip");
            //attributes.setAttribute('data-placement', "top");
            attributes.setAttribute('title', tooltip_message);
            eval(`$('#${field_id}').tooltip();`);
        }
        if(TextareaFirstLoad === 0){
            if(field_required==='true'){
                attributes.setAttribute('required', 'true');
            }else{
                attributes.removeAttribute('required');
            }
            setTextareaFirstLoadState(1);
        }
    });

    // const handleBlur=()=>{
    //     setTimeout(() => {
    //         field_value = document.querySelector(`#${field_id}`).value;
    //         if(field_value){
    //             document.querySelector(`#${field_id}`).classList.add('valid');
    //             document.querySelector(`#${field_id}`).classList.remove('invalid');
    //         }
    //     }, 500)
    // }

    return (
        <div className={field_class_wrapper} id={'group-field-'+field_id}>
            <div className="form-group gov-co-form-group">
                <label htmlFor={field_id} className="form-label">{field_label} <span className="asterik-required">{field_required==='true' ? '*':null}</span></label>
                {/* <Textarea className="form-control gov-co-form-control" 
                /> */}
                <textarea className="form-control"
                    id={field_id} name={field_name} aria-describedby={aria_describedby}
                    placeholder={field_placeholder ? field_placeholder : ''}
                    rows={2}
                    //maxlength="200"
                    value={field_value} type={field_type}
                    //onBlur={event => {handleBlur()}}
                    onChange={event => {handleChange(field_id, event)}}>
                </textarea>
                {validateError || (document.querySelector(`#${field_id}`)?.value==='' && document.querySelector(`#${field_id}`)?.required) ? <p><small className="form-text invalid">{validateError}</small></p>: ''}
            </div>
        </div>
    )
}

export default Textarea
